import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import api from "#utils/api";
import { closeModal } from "#redux/slices/globalUISlice";
import Modal from "./Modal";
import BookCover from "#helpers/BookCover";

const SearchTBRModal = ({ handleClick, status }) => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!searchQuery || searchQuery.length == 0) {
      setSearchResults([]);
      return;
    }

    return () => setSearchResults([]);
  }, [searchQuery]);

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    api.get(`/search_library?q=${searchQuery}&status=${status || ""}`)
      .then((data) => setSearchResults(data))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const onClose = () => {
    setSearchQuery("");
    dispatch(closeModal());
  };

  const handleSelect = (book) => {
    handleClick(book);
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose}>
      <div className="md:w-[400px]">
        <h2 className="mb-6">
          {status == "finished" ? "Search your finished books" : "Search your library"}
        </h2>
        <form onSubmit={handleSearch}>
          <input
            type="text"
            className="w-full px-4 py-3 leading-tight my-auto border border-offBlack rounded-default focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </form>

        <div className="mt-4">
          {loading ? (
            <div>Loading...</div>
          ) : (
            searchResults.map((book) => (
              <div
                key={book.id}
                className="flex flex-row gap-4 cursor-pointer w-full p-3 hover:bg-mainGreen rounded-default"
                onClick={() => handleSelect(book)}
              >
                <BookCover book={book} clickable={false} size="w-[67px] h-[100px]" />
                <div className="flex flex-col gap-1 justify-center">
                  <b>{book.title}</b>
                  <div className="text-sm">by {book.author_name}</div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SearchTBRModal;
