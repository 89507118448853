import React from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "#redux/slices/globalUISlice";
import ReviewForm from "../review/ReviewForm";
import FullReview from "../review/FullReview";
import Modal from "./Modal";

const NewReviewModal = ({ userBook, handleReviewSubmit, review, isOtherUser }) => {
  const dispatch = useDispatch();

  const onReviewSubmit = (data) => {
    handleReviewSubmit(data);
    dispatch(closeModal());
  };

  const showForm = review || ["dnf", "finished"].includes(userBook?.status);

  return (
    <Modal isOpen>
      <div className="w-[300px] md:w-[500px]">
        {isOtherUser ? (
          <FullReview reviewId={review?.id || userBook.review?.id} />
        ) : showForm ? (
          <ReviewForm userBook={userBook} postSubmitHandler={onReviewSubmit} review={review} isModal={true} />
        ) : (
          <>
            <p className="text-base font-bold">Whoops, looks like you're not done reading yet.</p>
            <p className="text-sm text-offBlack mt-2">Once you're done, you can leave a review here.</p>
          </>
        )}
      </div>
    </Modal>
  );
};

export default NewReviewModal;
