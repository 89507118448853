import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rating } from "@mui/material";
import Collapsible from "react-collapsible";
import clsx from "clsx";
import pluralize from "pluralize";
import { timeAgo } from "#utils/utils";
import Comment from "../forum/showPost/Comment";
import CommentForm from "../forum/showPost/CommentForm";
import { DNFTag } from "#helpers/Tag";
import UserProvidedContent from "#helpers/richText/UserProvidedContent";
import api from "#utils/api";
import Loader from "#helpers/Loader";
import BookCover from "#helpers/BookCover";
import Menu from "#helpers/Menu";
import { openModal } from "#redux/slices/globalUISlice";
import { getMaterialIcon } from "#utils/icons";
const FavoriteBorderOutlinedIcon = getMaterialIcon("FavoriteBorderOutlined");
const FavoriteOutlinedIcon = getMaterialIcon("FavoriteOutlined");
const StarRoundedIcon = getMaterialIcon("StarRounded");
const ExpandMoreOutlinedIcon = getMaterialIcon("ExpandMoreOutlined");

const StarRating = ({ text, value, isTrigger }) => {
  // NOTE: this is obnoxious because of the chevron trigger in the Collapsible component below -
  // flex positioning messes up the chevron positioning (but only on actual mobile devices)
  return (
    <span className={clsx("mb-1 text-xs md:text-sm", !isTrigger && "flex flex-row gap-2 items-center")}>
      {text && <span className="w-[70px] md:w-[120px]">{`${text}: `}</span>}

      <Rating
        name={`${text}-rating`}
        value={Number(value ?? 0)}
        precision={0.1}
        size="small"
        readOnly
        icon={<StarRoundedIcon className="stroke-offBlack text-yellow" fontSize="inherit" />}
        emptyIcon={<StarRoundedIcon className="stroke-offBlack text-white" fontSize="inherit" />}
      />
      <span className={clsx("mx-2", isTrigger && "relative -top-[2px]")}>{value > 0 && value}</span>
    </span>
  );
};

// TODO: update this to accept pre-loaded review for performance
const FullReview = ({ reviewId, setCardReview }) => {
  const dispatch = useDispatch();
  const { currentUserId, currentUser } = useSelector((state) => state.user);
  const [review, setReview] = useState();
  const [comments, setComments] = useState([]);
  const [loadingLike, setLoadingLike] = useState(false);
  const [moreComments, setMoreComments] = useState(false);
  const [loading, setLoading] = useState(false);
  const pageRef = useRef(1);
  const chevron = "relative after:transform after:transition-all after:font-sans after:content-['›'] after:absolute";

  if (!reviewId) return;

  useEffect(() => {
    setLoading(true);

    api
      .get(`/reviews/${reviewId}`)
      .then((data) => {
        setReview(data.review);
        setComments(data.comments);
        if (data.total_pages > 1) setMoreComments(true);
      })
      .catch((error) => {
        console.error("Error fetching review:", error);
      })
      .finally(() => setLoading(false));

    return () => {
      setComments([]);
      setMoreComments(false);
      pageRef.current = 1;
    };
  }, [reviewId]);

  const handleLoadMore = () => {
    setLoading(true);
    pageRef.current += 1;

    api
      .get(`/reviews/${reviewId}?page=${pageRef.current}`)
      .then((data) => {
        setComments((prevState) => [...prevState, ...data.comments]);
        if (data.total_pages <= pageRef.current) setMoreComments(false);
      })
      .finally(() => setLoading(false));
  };

  const onUpdateReview = (newData) => {
    setReview(newData);
    setCardReview && setCardReview(newData);
  }

  const handleCommentSuccess = (data) => {
    setComments((prevState) => [...prevState, data]);
    onUpdateReview(data.resource);
  };

  const handleEdit = () => {
    const handleReviewSubmit = ({ newReview }) => onUpdateReview(newReview);
    const data = { review, handleReviewSubmit, isOtherUser: review?.user_id !== currentUser?.uuid };
    dispatch(openModal({ name: "newReview", data }));
  };

  const handleClickLike = (event) => {
    event.stopPropagation();
    if (!currentUserId) return dispatch(openModal("loginPrompt"));

    setLoadingLike(true);
    api.post(`/reviews/${review.id}/${review.is_liked ? "remove_like" : "like"}`)
      .then((data) => onUpdateReview({ ...review, upvotes: data.upvotes, is_liked: data.is_liked }))
      .catch((e) => console.log("Error saving like: ", e))
      .finally(() => setLoadingLike(false));
  };

  const handleFlag = () => {
    const body = (
      <>
        Flag this review if it violates Pagebound's{" "}
        <a href="https://support.pagebound.co/terms_of_use" target="_blank">
          Terms of Use
        </a>
        . We will remove the review until we can do a manual review. Since we are a small company, this review may take
        up to 24 hours.
        <br />
        <br />
        Thanks for your help moderating the Pagebound community!
      </>
    );
    dispatch(
      openModal({
        name: "confirmation",
        data: {
          title: "Are you sure you want to flag this review?",
          body,
          confirmText: "Yes, flag",
          onConfirm: () => {
            api.post(`/flags`, { resource_type: "Review", resource_id: review.id })
              .then(() => onUpdateReview({ ...review, is_flagged: true }));
          },
        },
      })
    );
  };

  if (loading || !review) return <Loader />;

  const actionItems = [
    review?.user_id === currentUser?.uuid && { text: "edit", onClick: handleEdit },
    currentUserId &&
      review?.user_id !== currentUser?.uuid &&
      !review?.is_flagged && { text: "flag", onClick: handleFlag },
  ];

  return (
    <>
      <div className="flex w-full text-xs justify-between items-start mb-3">
        <div className="flex gap-6 items-center">
          <BookCover book={review.book} size="w-[54px] h-[80px]" />
          <p className="text-base whitespace-nowrap">
            <a href={`/users/${review.username}`}>{review.username}'s</a> review
          </p>
        </div>
        <div className="flex gap-3 items-center">
          <div className="hidden md:block flex-none text-xs text-gray">{timeAgo(review.created_at)}</div>
          {review.is_edited && <div className="hidden md:block text-xs text-gray">Edited</div>}
          {review.is_dnf && <DNFTag />}
          {currentUserId && <Menu items={actionItems} />}
        </div>
      </div>
      <div className="font-space font-bold flex flex-col">
        <Collapsible
          trigger={<StarRating value={review.overall_rating} isTrigger />}
          triggerClassName={`${chevron} after:rotate-90`}
          triggerOpenedClassName={`${chevron} after:-rotate-90`}
          transitionTime={200}
          open={true}
        >
          <StarRating text="Enjoyment" value={review.entertainment_rating || 0} />
          <StarRating text="Quality" value={review.quality_rating || 0} />
          <StarRating text="Characters" value={review.character_rating || 0} />
          <StarRating text="Plot" value={review.plot_rating || 0} />
        </Collapsible>
        <div className="flex flex-row gap-2 items-center">
          {review.emojis.map((emoji, index) => (
            <div className="text-[20px]" key={index}>
              {emoji}
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col overflow-x-hidden pb-6">
          <div className={`flex flex-col gap-3 ${comments?.length >= 1 && "border-b border-b-gray pb-3"}`}>
            <div className="flex gap-2.5 justify-between items-center md:justify-normal">
              <h4 className="leading-none">{review.is_flagged || review.deleted_at ? "[deleted]" : review.title}</h4>
            </div>

            <UserProvidedContent resource={review} content={review.review} type="review" />

            <div className="flex justify-between mt-2">
              <div className="flex self-start flex-row gap-1 items-center justify-center">
                <button onClick={handleClickLike} disabled={loadingLike}>
                  {review.is_liked ? (
                    <FavoriteOutlinedIcon color="#FF695F" fontSize="small" className="text-red" />
                  ) : (
                    <FavoriteBorderOutlinedIcon color="#D2D2D2" fontSize="small" className="text-gray hover:text-red" />
                  )}
                </button>
                <div className="text-gray text-xs text-center leading-none">{review.upvotes}</div>
              </div>
              <div className="text-gray text-xs">{pluralize("comments", review.comment_count, true)}</div>
            </div>
          </div>
          {comments.map((c) => (
            <Comment key={c.uuid} commentData={c} reviewId={review.id} />
          ))}
          {moreComments && (
            <button className="bg-transparent p-2 self-center mt-3 text-sm" disabled={loading} onClick={handleLoadMore}>
              {loading ? (
                "Loading..."
              ) : (
                <>
                  View More <ExpandMoreOutlinedIcon fontSize="small" />
                </>
              )}
            </button>
          )}
        </div>

        <CommentForm resourceType="Review" resourceId={review.id} onSuccess={handleCommentSuccess} />
      </div>
    </>
  );
};

export default FullReview;
