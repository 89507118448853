import React, { useState, useEffect } from "react";
import api from "#utils/api";
import Button from "#helpers/Button";
import Loader from "#helpers/Loader";

const AddMissingBookForm = () => {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    api
      .post("/goodreads/add_book", { goodreads_url: url })
      .then(() => setIsSuccess(true))
      .catch(() => setErrorMessage("Oops, something went wrong. Please try again later."))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="w-full flex flex-col gap-3">
      <h4>Thanks for your help expanding our library!</h4>
      {isLoading ? (
        <div className="self-center">
          <Loader />
        </div>
      ) : errorMessage ? (
        <p>{errorMessage}</p>
      ) : isSuccess ? (
        <p>
          We're on it! The book you added may take a moment to appear in search results.
        </p>
      ) : (
        <>
          <p>Please search for the book on Goodreads and paste the link below:</p>
          <form onSubmit={handleSubmit} className="w-full flex flex-col gap-3 items-end justify-center">
            <input
              className="w-full rounded-default border h-[40px] text-base px-4"
              placeholder="https://www.goodreads.com/book/show/..."
              type="text"
              value={url}
              onChange={(e) => {
                setErrorMessage("");
                setUrl(e.target.value);
              }}
            />
            <Button
              onClick={handleSubmit}
              text="Submit"
              color="bg-neonBlue"
              disabled={isLoading || !url.includes("goodreads.com/book/show/")}
            />
          </form>
        </>
      )}
    </div>
  );
};

export default AddMissingBookForm;
