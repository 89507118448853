import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "#redux/slices/globalUISlice";
import BookCard from "./BookCard";
import ClubCard from "./ClubCard";
import QuestCard from "./QuestCard";
import ListCard from "./ListCard";
import PostCard from "./PostCard";
import ReviewCard from "./ReviewCard";
import ReadingChallengeCard from "./ReadingChallengeCard";
import Button from "#helpers/Button";
import api from "#utils/api";
import ChatBubble from "#assets/chat-bubble.svg";
import { getMaterialIcon } from "#utils/icons";
const FavoriteBorderOutlinedIcon = getMaterialIcon("FavoriteBorderOutlined");
const FavoriteOutlinedIcon = getMaterialIcon("FavoriteOutlined");

const FeedCard = ({ activityItemData, showReply = true }) => {
  const dispatch = useDispatch();
  const [activityItem, setActivityItem] = useState(activityItemData);
  const [loadingLike, setLoadingLike] = useState(false);
  const { currentUserId } = useSelector((state) => state.user);

  useEffect(() => {
    setActivityItem(activityItemData);
  }, [activityItemData]);

  const ACTIVITY_TYPE_COLOR_MAP = {
    book_post: "bg-mainBlue",
    club_post: "bg-pastelBlue",
    global_announcement: "bg-pastelPink",
    global_item: "bg-pastelPink",
    created_comment: "bg-pastelOrange",
  }; // defaults to bg-pastelPurple if not included here

  const LIKEABLE_ACTIVITY_TYPES = [
    "finished_book",
    "dnf_book",
    "tbr_book",
    "start_book",
    "completed_yearly_goal",
    "set_yearly_goal",
    "joined_quest",
    "completed_quest",
    "joined_club",
    "created_club",
  ];

  const ONBOARDING_ACTIVITY_TYPE_MAP = {
    join_club: {
      color: "bg-pastelBlue",
      title: "Join more Clubs!",
      description:
        "Like book clubs or subreddits, Clubs are the ideal way to find your community and get curated recommendations.",
      cta: "Discover Clubs",
      link: "/discover/clubs",
    },
    create_list: {
      color: "bg-gradient-to-b from-pastelBlue to-pastelPink",
      title: "You haven't created a List yet...",
      description: "Are you ready to be a tastemaker? Curate a collection of books and earn votes from the community.",
      cta: "Create a List",
      link: "/lists",
    },
    set_up_year_goal: {
      color: "bg-gradient-to-b from-pastelOrange to-pastelGreen",
      title: "Start your Yearly Reading Challenge",
      description: "Set your goal for the year, and keep track of the books you've finished.",
      cta: "Take me there",
      link: "/reading-challenge",
    },
  };

  const OnboardingActivityCard = ({ type }) => {
    return (
      <div
        className={`${ONBOARDING_ACTIVITY_TYPE_MAP[type].color} w-full max-w-[900px] flex flex-col md:flex-row justify-between items-center rounded-default p-4 md:p-6 border border-offBlack shadow-main `}
      >
        <div className="flex flex-col gap-2 md:gap-3 text-left pr-5">
          <p className="font-bold">{ONBOARDING_ACTIVITY_TYPE_MAP[type].title}</p>
          <p>{ONBOARDING_ACTIVITY_TYPE_MAP[type].description}</p>
        </div>
        <div className="self-end md:self-center flex-none mt-3 md:mt-0">
          <Button
            type="link"
            text={ONBOARDING_ACTIVITY_TYPE_MAP[type].cta}
            to={ONBOARDING_ACTIVITY_TYPE_MAP[type].link}
            color="bg-fuschia"
          />
        </div>
      </div>
    );
  };

  const renderCard = (resource_type, resource) => {
    switch (resource_type) {
      case "Post":
        return <PostCard postData={resource} />;
      case "Book":
        return <BookCard book={resource} variant="large" />;
      case "Club":
        return <ClubCard club={resource} />;
      case "Challenge":
        return <QuestCard quest={resource} />;
      case "List":
        return <ListCard listData={resource} />;
      case "Review":
        return <ReviewCard review={resource} variant="with-image" />;
      case "YearlyGoal":
        return <ReadingChallengeCard user={resource.user} variant="feed" />;
      case "ActivityItem":
        return <FeedCard activityItemData={resource} />;
      case "Comment":
        return renderCard(resource.resource_type, resource.resource);
      default:
        return <p>Unhandled resource_type in FeedCard: {resource_type}</p>;
    }
  };

  if (activityItem.action_type in ONBOARDING_ACTIVITY_TYPE_MAP) {
    return <OnboardingActivityCard type={activityItem.action_type} />;
  }

  const renderTitle = () => {
    if (activityItem.username) {
      const truncatedTitle = activityItem.title.split(" ").slice(1).join(" ");
      return (
        <p className="font-bold">
          <a href={`/users/${activityItem.username}`}>{activityItem.username}</a> {truncatedTitle}
        </p>
      );
    }

    if (activityItem.action_type === "book_post" || activityItem.action_type === "club_post") {
      return (
        <p className="font-bold">
          {activityItem.title}{" "}
          <a href={activityItem.forum_link}>
            {" "}
            {/* {activityItem.action_type === "book_post" ? "📗 " : "👥 "} */}
            {activityItem.forum_name}
          </a>{" "}
          forum
        </p>
      );
    }
    return <p className="font-bold">{activityItem.title}</p>;
  };

  const handleLike = (e) => {
    e.stopPropagation();
    setLoadingLike(true);
    api.post(`/feed/like`, { id: activityItem.id });
    setActivityItem((prevState) => ({ ...prevState, is_liked: true, upvotes: prevState.upvotes + 1 }));
    setLoadingLike(false);
  };

  const handleRemoveLike = (e) => {
    e.stopPropagation();
    setLoadingLike(true);
    api.post(`/feed/remove_like`, { id: activityItem.id });
    setActivityItem((prevState) => ({ ...prevState, is_liked: false, upvotes: prevState.upvotes - 1 }));
    setLoadingLike(false);
  };

  return (
    <div
      className={`${
        activityItem.action_type === "created_comment" && activityItem.resource.resource_type === "ActivityItem" ? "bg-transparent shadow-none border-none !p-0"
        : (ACTIVITY_TYPE_COLOR_MAP[activityItem.action_type] || "bg-pastelPurple")
      } w-full max-w-[900px] rounded-default border border-offBlack text-left shadow-main flex flex-col p-4 gap-2`}
    >
      {renderTitle()}
      {renderCard(activityItem.resource_type, activityItem.resource)}
      {LIKEABLE_ACTIVITY_TYPES.includes(activityItem.action_type) && showReply && (
        <div
          className="flex gap-3 justify-start items-center mt-1 ml-1 font-bold cursor-pointer"
          onClick={() => {
            dispatch(
              openModal({
                name: "showActivityItem",
                data: { activityItemId: activityItem.id, setCardActivityItem: setActivityItem },
              })
            );
          }}
        >
          <div className="flex gap-1 self-start items-center justify-center rounded-default border h-[26px] px-2.5 min-w-[51px] bg-transparent border-gray">
            <button
              className="flex justify-start items-center"
              onClick={activityItem.is_liked ? handleRemoveLike : handleLike}
              disabled={loadingLike || activityItem.user_id === currentUserId}
            >
              {activityItem.is_liked ? (
                <FavoriteOutlinedIcon color="#FF695F" fontSize="small" className="text-red" />
              ) : (
                <FavoriteBorderOutlinedIcon
                  color="#D2D2D2"
                  fontSize="small"
                  className={`text-gray ${activityItem.user_id !== currentUserId && "hover:text-red"}`}
                />
              )}
            </button>
            <div className="text-xs text-center text-gray">{activityItem.upvotes}</div>
          </div>
          <div className="items-center text-xs text-gray rounded-default border py-1 px-3 bg-transparent border-gray">
            <img src={ChatBubble} className="inline" />
            {"  "}
            {activityItem.comment_count}
          </div>
          <div className="text-gray text-xs">Reply</div>
        </div>
      )}
    </div>
  );
};

export default FeedCard;
