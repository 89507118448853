import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import pluralize from "pluralize";
import { fetchPostComments, upvotePost, downvotePost, updatePost } from "#redux/slices/forumSlice";
import { setModalDirty } from "#redux/slices/globalUISlice";
import { timeAgo } from "#utils/utils";
import { SpoilerTag } from "#helpers/Tag";
import Button from "#helpers/Button";
import Votes from "#helpers/Votes";
import pin from "#assets/pin.svg";
import UserProvidedContent from "#helpers/richText/UserProvidedContent";
import Comment from "./Comment";
import CommentForm from "./CommentForm";
import PostActions from "./PostActions";
import { getMaterialIcon } from "#utils/icons";
const ExpandMoreOutlinedIcon = getMaterialIcon("ExpandMoreOutlined");

const FullPost = ({ postData, setCardPost }) => {
  const dispatch = useDispatch();
  const [post, setPost] = useState(postData);
  const [isEditing, setIsEditing] = useState(false);
  const [newContent, setNewContent] = useState(post.content);
  const [comments, setComments] = useState([]);
  const [moreComments, setMoreComments] = useState(false);
  const [loading, setLoading] = useState(false);
  const pageRef = useRef(1);
  const textareaRef = useRef(null);

  if (!post) return;

  useEffect(() => {
    setLoading(true);
    dispatch(fetchPostComments({ postId: post.id, page: 1 }))
      .unwrap()
      .then((data) => {
        setComments(data.comments);
        if (data.total_pages > 1) setMoreComments(true);
      })
      .finally(() => setLoading(false));

    return () => {
      setComments([]);
      setMoreComments(false);
      pageRef.current = 1;
    };
  }, []);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto"; // Reset height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on content
    }
  }, [newContent, isEditing]);

  const handleLoadMore = () => {
    setLoading(true);
    pageRef.current += 1;
    dispatch(fetchPostComments({ postId: post.id, page: pageRef.current }))
      .unwrap()
      .then((data) => {
        setComments((prevState) => {
          const newComments = data.comments.filter(
            (newComment) => !prevState.some((existingComment) => existingComment.id === newComment.id)
          );
          return [...prevState, ...newComments];
        });
        setPost(data.post);

        if (data.total_pages <= pageRef.current) setMoreComments(false);
      })
      .finally(() => setLoading(false));
  };

  const onUpdatePost = (newData) => {
    setPost(newData);
    setCardPost && setCardPost(newData);
  }

  const handleUpvote = () => {
    dispatch(upvotePost(post.id)).then((data) => onUpdatePost(data.payload));
  };

  const handleDownvote = () => {
    dispatch(downvotePost(post.id)).then((data) => onUpdatePost(data.payload));
  };

  const handleCommentSuccess = (data) => {
    setComments((prevState) => [...prevState, data]);
    onUpdatePost(data.resource);
  };

  const onChangeContent = (event) => {
    dispatch(setModalDirty(true));
    setNewContent(event.target.value);
  };

  const handleSubmitEdit = () => {
    const postData = { is_edited: true, content: newContent };
    dispatch(updatePost({ postId: post.id, postData }))
      .unwrap()
      .then((data) => {
        onUpdatePost(data);
        setIsEditing(false);
        dispatch(setModalDirty(false));
      });
  };

  return (
    <>
      <div className="flex w-full text-xs justify-between items-center mb-3">
        <div className="flex gap-6 text-gray items-center">
          <a href={`/users/${post.username}`}>{post.username}</a>
          <div>{timeAgo(post.created_at)}</div>
          {post.is_edited && <div>Edited</div>}
        </div>
        <div className="flex gap-3 items-center">
          {post.is_pinned && <img src={pin} alt="pin" className="w-4 h-4" />}
          {isEditing ? (
            <Button onClick={handleSubmitEdit} text="Update" color="bg-neonBlue" />
          ) : (
            <PostActions post={post} setPost={onUpdatePost} onEdit={() => setIsEditing(true)} />
          )}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col overflow-x-hidden pb-10">
          <div className={`flex flex-col gap-3 ${comments?.length >= 1 && "border-b border-b-gray pb-3"}`}>
            <div className="flex gap-2.5 justify-between items-center md:justify-normal">
              <h4 className="leading-none">{post.is_flagged || post.deleted_at ? "[deleted]" : post.title}</h4>
              {post.is_spoiler && <SpoilerTag />}
            </div>
            {isEditing ? (
              <textarea
                value={newContent}
                onChange={onChangeContent}
                ref={textareaRef}
                className="w-full ml-0 text-left text-[16px] leading-5 py-3 px-4 my-auto border border-offBlack rounded-default focus:outline-none focus:ring-1 focus:border-seafoam focus:ring-seafoam"
              />
            ) : (
              <UserProvidedContent resource={post} content={post.content} type="post" />
            )}
            <div className="flex justify-between">
              <Votes
                handleUpvote={handleUpvote}
                handleDownvote={handleDownvote}
                userVote={post.user_vote}
                upvotes={post.upvotes}
              />
              <div className="text-gray text-xs">{pluralize("comments", post.comment_count, true)}</div>
            </div>
          </div>
          {comments.map((c) => (
            <Comment key={c.uuid} commentData={c} postId={post.id} />
          ))}
          {moreComments && (
            <button className="bg-transparent p-2 self-center mt-3 text-sm" disabled={loading} onClick={handleLoadMore}>
              {loading ? (
                "Loading..."
              ) : (
                <>
                  View More <ExpandMoreOutlinedIcon fontSize="small" />
                </>
              )}
            </button>
          )}
        </div>

        <CommentForm resourceType="Post" resourceId={post.id} onSuccess={handleCommentSuccess} />
      </div>
    </>
  );
};

export default FullPost;
