import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "#utils/api.js";

export const fetchForumPosts = createAsyncThunk(
  "forum/fetchForumPosts",
  ({ resource_id, resource_type, page, filters, sortBy }) =>
    api.get(
      `/forums/find_by_resource?resource_id=${resource_id}&resource_type=${resource_type}&page=${page}&only_following=${filters.onlyFollowing}&post_type=${filters.postType}&sort_by=${sortBy}`
    )
);

export const fetchPostComments = createAsyncThunk("forum/fetchPostComments", ({ postId, page }) =>
  api.get(`/posts/${postId}/comments?page=${page}`)
);

export const fetchNestedComments = createAsyncThunk("forum/fetchNestedComments", ({ commentId, page }) =>
  api.get(`/comments/${commentId}/nested_comments?page=${page}`)
);

export const createPost = createAsyncThunk("forum/createPost", (postData) => api.post(`/posts`, { post: postData }));

export const updatePost = createAsyncThunk("forum/updatePost", ({ postId, postData }) =>
  api.put(`/posts/${postId}`, { post: postData })
);

export const deletePost = createAsyncThunk("forum/deletePost", (postId) => api.destroy(`/posts/${postId}`));

export const updateComment = createAsyncThunk("forum/updateComment", ({ commentId, commentData }) =>
  api.put(`/comments/${commentId}`, { comment: commentData })
);

export const deleteComment = createAsyncThunk("forum/deleteComment", (commentId) =>
  api.destroy(`/comments/${commentId}`)
);

export const upvoteComment = createAsyncThunk("forum/upvoteComment", (commentId) =>
  api.post(`/comments/${commentId}/upvote`)
);

export const downvoteComment = createAsyncThunk("forum/downvoteComment", (commentId) =>
  api.post(`/comments/${commentId}/downvote`)
);

export const upvotePost = createAsyncThunk("forum/upvotePost", (postId) => api.post(`/posts/${postId}/upvote`));

export const downvotePost = createAsyncThunk("forum/downvotePost", (postId) => api.post(`/posts/${postId}/downvote`));

const initialState = {
  forum: null,
  posts: [],
  error: null,
  loading: false,
};

export const forumSlice = createSlice({
  name: "forum",
  initialState,
  reducers: {
    clearForum: (state) => {
      return { ...state, forum: null, posts: [], error: null };
    },
    pinPost: (state, action) => {
      if (state.forum?.id !== action.payload.forum_id) return state;
      const pinnedPost = action.payload;
      const updatedPosts = [pinnedPost, ...state.posts.filter((post) => post.id !== pinnedPost.id)];
      return { ...state, posts: updatedPosts };
    },
    unpinPost: (state, action) => {
      if (state.forum?.id !== action.payload.forum_id) return state;
      const unpinnedPost = action.payload;
      const updatedPosts = state.posts.filter((post) => post.id !== unpinnedPost.id);
      return { ...state, posts: [...updatedPosts, unpinnedPost] };
    },
    newPost: (state, action) => {
      if (state.forum?.id !== action.payload.forum_id) return state;
      const newPost = action.payload;
      const pinned = state.posts.filter((post) => post.is_pinned);
      const posts = state.posts.filter((post) => !post.is_pinned);
      return { ...state, posts: [...pinned, newPost, ...posts] };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchForumPosts.pending, (state, action) => {
      return { ...state, loading: action.meta.arg.page === 1, error: null };
    });
    builder.addCase(fetchForumPosts.rejected, (state, action) => {
      const error = action.error.code === "ERR_BAD_REQUEST" ? "Forum not yet created" : action.error.message;
      return { ...state, loading: false, error };
    });
    builder.addCase(fetchForumPosts.fulfilled, (state, action) => {
      let updatedPosts = action.payload.posts;
      let pinned = action.payload.pinned;
      let oldPosts = state.posts.filter((post) => !post.is_pinned);
      if (action.meta.arg.page !== 1) {
        const existingPostIds = new Set(oldPosts.map((post) => post.id));
        const filteredNewPosts = updatedPosts.filter((p) => !existingPostIds.has(p.id));
        updatedPosts = [...oldPosts, ...filteredNewPosts];
      }

      return {
        posts: [...pinned, ...updatedPosts],
        forum: action.payload.forum,
        loading: false,
        error: null,
      };
    });
    builder.addCase(createPost.fulfilled, (state, action) => {
      const newPost = action.payload;
      const pinned = state.posts.filter((post) => post.is_pinned);
      const posts = state.posts.filter((post) => !post.is_pinned);
      return { ...state, loading: false, posts: [...pinned, newPost, ...posts] };
    });
    builder.addCase(updatePost.fulfilled, (state, action) => {
      const updatedPost = action.payload;
      const updatedPosts = state.posts.map((post) => {
        if (post.id === updatedPost.id) return updatedPost;
        return post;
      });
      return { ...state, posts: updatedPosts };
    });
    builder.addCase(upvotePost.fulfilled, (state, action) => {
      const updatedPost = action.payload;
      const updatedPosts = state.posts.map((post) => {
        if (post.id === updatedPost.id) return updatedPost;
        return post;
      });
      return { ...state, posts: updatedPosts };
    });
    builder.addCase(downvotePost.fulfilled, (state, action) => {
      const updatedPost = action.payload;
      const updatedPosts = state.posts.map((post) => {
        if (post.id === updatedPost.id) return updatedPost;
        return post;
      });
      return { ...state, posts: updatedPosts };
    });
  },
});

export const { clearForum, pinPost, unpinPost, newPost } = forumSlice.actions;
export default forumSlice.reducer;
