import { useDispatch, useSelector } from "react-redux";
import { navigate } from "vike/client/router";
import Modal from "./Modal";
import FullPost from "../forum/showPost/FullPost";
import expand from "#assets/graphic-expand.svg";
import { closeModal } from "#redux/slices/globalUISlice";

const ShowPostModal = ({ postId, postData, setCardPost }) => {
  const dispatch = useDispatch();
  const post = postData || useSelector((state) => state.forum.posts.find((p) => p.id === postId));
  if (!post) return;

  return (
    <Modal
      isOpen
      classNames={{
        container: "min-w-[95%] md:min-w-[90%] lg:min-w-[700px] lg:max-w-[800px] !overflow-y-clipped",
        margin: "mt-[30%] md:mt-[120px]",
      }}
      headerActions={[
        {
          icon: expand,
          onClick: () => {
            navigate(`/posts/${postId}`);
            dispatch(closeModal());
          },
        },
      ]}
    >
      <FullPost postData={post} setCardPost={setCardPost} />
    </Modal>
  );
};

export default ShowPostModal;
