import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import { Rating } from "@mui/material";
import { Formik, Field, FieldArray, Form, useField, ErrorMessage } from "formik";
import * as Yup from "yup";
import { setModalDirty } from "#redux/slices/globalUISlice";
import api from "#utils/api";
import Modal from "../modals/Modal";
import Switch from "#helpers/Switch";
import { getMaterialIcon } from "#utils/icons";
import { Slider } from "@mui/material";
const StarRoundedIcon = getMaterialIcon("StarRounded");
const RemoveCircleIcon = getMaterialIcon("RemoveCircle");
const AddCircleOutlineIcon = getMaterialIcon("AddCircleOutline");

const ReviewForm = ({ postSubmitHandler, userBook, review, isModal }) => {
  const dispatch = useDispatch();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    const action = review
      ? api.put(`/reviews/${review.id}`, { review: { ...values, is_edited: true } })
      : api.post(`/reviews`, { review: values });

    action
      .then((data) => postSubmitHandler({ newReview: data }))
      .catch((error) => {
        setStatus({ error: "An unexpected error occurred. Please check your form and try again." });
        console.error("Error saving review: ", error);
      })
      .finally(() => setSubmitting(false));
  };

  const StarRating = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const isSelected = (v) => (v === value ? "selected" : "");
    const [hover, setHover] = useState(0);

    return (
      <div className="relative flex z-0 flex-row gap-2 items-center mb-1 text-sm md:text-base font-bold font-space">
        {props.text && <div className="w-[90px] md:w-[120px] text-left">{`${props.text}: `}</div>}
        <div className="flex flex-row gap-2 items-center">
          <div className="relative">
            <Rating
              name={`${props.name}-rating`}
              value={Number(value ?? 0)}
              precision={0.5}
              size="medium"
              onChange={(e, newValue) => {
                setValue(newValue);
                setHover(newValue);
              }}
              onChangeActive={(event, newHover) => setHover(newHover)}
              icon={<StarRoundedIcon className="stroke-offBlack text-yellow" fontSize="medium" />}
              emptyIcon={<StarRoundedIcon className="stroke-offBlack text-white" fontSize="medium" />}
            />
            <div className="absolute md:hidden w-full h-full -top-[12px] left-0">
              <Slider
                value={Number(value ?? 0)}
                onChange={(e, newValue) => {
                  setValue(newValue);
                  setHover(newValue);
                }}
                step={0.5}
                min={0}
                max={5}
                track={false}
                sx={{
                  width: "100%",
                  height: "100%",
                  color: "transparent",
                  "& .MuiSlider-thumb": {
                    backgroundColor: "transparent",
                    boxShadow: "none !important",
                    "&::before": {
                      boxShadow: "none",
                    },
                    "&::after": {
                      boxShadow: "none",
                    },
                    "&:hover, &.Mui-focusVisible": {
                      boxShadow: "none",
                    },
                  },
                }}
              />
            </div>
          </div>

          {(hover > 0 && hover) || value}
        </div>
      </div>
    );
  };

  const SpoilerSwitch = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const isSelected = (v) => (v === value ? "selected" : "");

    return (
      <div className="flex items-center">
        <label className="mr-4 font-bold" htmlFor="hasSpoilers">
          Does your post include spoilers?
        </label>
        <Switch id="hasSpoilers" isChecked={value} onChange={() => setValue(!value)} />
      </div>
    );
  };

  const ReviewText = (props) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const isSelected = (v) => (v === value ? "selected" : "");
    const textareaRef = useRef(null);

    useEffect(() => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.height = "auto"; // Reset height
        textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on content
      }
    }, [value]);

    const handleChange = (e) => {
      setValue(e.target.value);
    };

    return (
      <textarea
        ref={textareaRef}
        minLength="2"
        placeholder="Write your review! (optional)"
        value={value}
        onChange={handleChange}
        className="border py-2 text-base border-gray px-[10px] h-20 rounded-[20px] w-full focus:outline-none resize-none placeholder:text-gray text-offBlack"
      />
    );
  };

  const reviewSchema = Yup.object().shape({
    overall_rating: Yup.number().required("Required"),
    quality_rating: Yup.number().nullable(true),
    entertainment_rating: Yup.number().nullable(true),
    plot_rating: Yup.number().nullable(true),
    character_rating: Yup.number().nullable(true),
    is_spoiler: Yup.boolean(),
    is_dnf: Yup.boolean(),
    review: Yup.string().optional(),
    emojis: Yup.array().max(3, "Maximum 3 emojis"),
    user_book_id: Yup.number(),
    book_id: Yup.number(),
  });

  return (
    <Formik
      validationSchema={reviewSchema}
      validateOnMount={true}
      validateOnChange={true}
      onSubmit={handleSubmit}
      initialValues={{
        // hacky way to pass the selected star value from button click to the form
        overall_rating: userBook?.review?.overall_rating || review?.overall_rating || null,
        quality_rating: review?.quality_rating || null,
        entertainment_rating: review?.entertainment_rating || null,
        plot_rating: review?.plot_rating || null,
        character_rating: review?.character_rating || null,
        is_spoiler: review?.is_spoiler || false,
        review: review?.review || undefined,
        emojis: review?.emojis || [],
        book_id: review?.book_id || userBook?.book_id,
        user_book_id: userBook?.id,
        is_dnf: userBook?.status === "dnf",
      }}
    >
      {({ errors, values, isSubmitting, isValid, status }) => (
        <Form
          className="w-full m-auto flex flex-col items-start gap-6"
          onChange={() => isModal && dispatch(setModalDirty(true))}
        >
          <div className="flex flex-col gap-1">
            <StarRating name="overall_rating" text="Overall" />
            <StarRating name="entertainment_rating" text="Enjoyment" />
            <StarRating name="quality_rating" text="Quality" />
            <StarRating name="character_rating" text="Characters" />
            <StarRating name="plot_rating" text="Plot" />
          </div>
          <div className="flex flex-col gap-1 w-full items-start">
            <p className="font-bold">Select up to 3 emojis</p>
            <FieldArray
              name="emojis"
              render={(arrayHelpers) => (
                <div className="flex gap-4 items-start">
                  {values.emojis?.map((emoji, index) => (
                    <div key={index} className="flex flex-col">
                      <div className="text-[25px]">{emoji}</div>
                      <button type="button" onClick={() => arrayHelpers.remove(index)}>
                        <RemoveCircleIcon fontSize="small" sx={{ color: "#FF695F" }} />
                      </button>
                    </div>
                  ))}
                  {values.emojis?.length < 3 && (
                    <button type="button" className="pt-2" onClick={() => setShowEmojiPicker(true)}>
                      <AddCircleOutlineIcon fontSize="small" sx={{ color: "#333" }} />
                    </button>
                  )}

                  <Modal isOpen={showEmojiPicker} onClose={() => setShowEmojiPicker(false)}>
                    <EmojiPicker
                      skinTonesDisabled
                      lazyLoadEmojis
                      previewConfig={{ showPreview: false }}
                      onEmojiClick={(emojiData, event) => {
                        if (values.emojis.length < 3) arrayHelpers.push(emojiData.emoji);
                        setShowEmojiPicker(false);
                      }}
                    />
                  </Modal>
                </div>
              )}
            />
            <ErrorMessage name="emojis" render={(msg) => <div className="text-red">{msg}</div>} />
          </div>
          <div className="flex flex-col gap-3 w-full items-start">
            <ReviewText name="review" />
            <ErrorMessage name="review" render={(msg) => <div className="text-red">{msg}</div>} />
          </div>
          <SpoilerSwitch name="is_spoiler" />
          <div className="w-full flex justify-end items-center">
            {status && status.error && <div className="text-red leading-none mr-4">{status.error}</div>}
            <button
              className="shadow-main bg-neonBlue min-w-[60px] h-fit whitespace-nowrap font-bold leading-none font-space text-[11px] md:text-[13px] rounded-[50px] px-3 py-1.5 md:py-[.5em] cursor-pointer border border-offBlack disabled:bg-offWhite disabled:shadow-none disabled:border-gray disabled:text-gray"
              disabled={!isValid || isSubmitting}
              type="submit"
            >
              {review ? "Update" : "Submit"}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ReviewForm;
